/* Header.css */
.header {
    max-width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: #fff;
    color: #06447d;
}

.languages {
    margin-left: 120px;
    display: flex;
    gap: 30px;
}

.languages span {
    font-size: 16px;
    cursor: pointer;
}
.languages span a {
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    color: #06447d;
}

.languages span:hover {
    text-decoration: underline;
}

.social-icons {
    margin-right: 50px;
    display: flex;
    color: #06447d;
    gap: 25px;
}

.icon-header {
    margin-left: 10px;
    color: #06447d;
}

.social-icons a {
    color: #06447d;
    font-size: 24px;
    text-decoration: none;
    font-size: 1.5rem;
}

.social-icons a:hover {
    color: #007bff;
}

@media (max-width: 1300px) {
    .icon-header {
        display: none;
        margin-left: 0px;
    }

}


@media (max-width: 768px) {
    .languages {
        margin-left: 0;
        gap: 10px;
    }
    .social-icons {
        margin-right: 0px;
    }
    .social-icons a {
        font-size: 1.4rem;
    }
    .languages span{
        font-size: 0.8rem;
    }
}




/* .button-24 {
 
}

.button-24:hover,
.button-24:active {
  background-color: initial;
  background-position: 0 0;
  color: #FF4742;
}

.button-24:active {
  opacity: .5;
} */