* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.collage-container {
    width: 100%; /* 100% width minus left and right margins */
    height: auto;
    margin: 0 auto;
    display: flex;
    padding: 70px;
    flex-wrap: wrap;
    gap: 30px;
    position: relative;
    background-color: #fff;
    overflow: hidden;
}

.collage-image {
    padding: 5px;
    border: 4px solid #2A629A;
    width: 20%; 
    height: auto;
    margin: 5px;
    object-fit: cover;
    flex-grow: 1;
}

@media (max-width: 1200px) {
    .collage-container {
        padding: 10px;
        width: calc(100% - 100px); /* Adjust width for smaller screens */
        height: auto;
    }
}

@media (max-width: 768px) {
    .collage-container {
        width: 100%; /* Full width for very small screens */
        margin: 0;
    }

    .collage-image {
        width: calc(50% - 10px); /* Adjust width for 2 columns with gap */
    }
}
