/* App.css */
body {
    margin: 0;
    font-family: Arial, sans-serif;
}

.container-test {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    background-color: #DDE6ED;
}

.news-h2 {
    font-family: "Playwrite NZ", cursive;
    font-size: 15px;
    color: #2A629A;
}

.news-h1 {
    margin-top: 0px;
    font-size: 40px;
    color: #2A629A;
}

header {
    margin-bottom: 30px;
}


.star {
    width: 90%;
    height: 30px;
    color: yellow;
    bottom: 10px;
    position: absolute;
}
.test-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
    gap: 30px;
}

.test-card {
    background: #2A629A;
    color: white;
    border: 1px solid #ddd;
    position: relative;
    border-radius: 8px;
    margin: 10px;
    padding: 20px;
    width: 350px;
    height: 500px;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.test-card img {
    width: 55%;
    height: 35%;
    border-radius: 50%;
    margin-bottom: 15px;
    border: 3px solid #fff;
    padding: 5px;
}

.test-card strong {
    display: block;
    margin-bottom: 10px;
    font-size: 1.2em;
}

.test-card p {
    color: #fff;
    font-size: 0.9em;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .container-test {
        height: auto;
    }

    .test-card {
        width: 300px;
        height: 400px;
    }

    .test-card img {
        height: 200px;
    }
}

@media (max-width: 768px) {
    .test-card {
        width: 100%;
        height: auto;
    }

    .star {
        height: 15px;
    }

    .test-card img {
        height: auto;
        gap: 0;
    }

    .test-cards {
        flex-direction: column;
        align-items: center;
    }

    .news-h1 {
        font-size: 30px;
    }

    .news-h2 {
        font-size: 12px;
    }
}

@media (max-width: 480px) {
    .news-h1 {
        font-size: 24px;
    }

    .news-h2 {
        font-size: 10px;
    }
}