.page-tours {
    width: 100%;
    height: auto;
    background-image: url(../../images/Background-tour.png);
    border-bottom: 2px solid white;
    align-items: center;
    justify-content: center;
}
.tours-page {
    margin: auto;
    width: 40%;
    height: auto;
    justify-content: center;
    align-items: center;
    text-align: left;
    background-color: #fff455a3;
    padding: 20px; /* Optional: Add some padding */
  }
  
  .tours-part-image {
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
  }
  .title-text {
    font-size: 0rem;
  }
  
  .tours-page h3, p {
    color: #fff;
    letter-spacing: 1.3px;
  }

  @media (max-width: 768px) {
    .tours-page {
      width: 100%;
    }
  }
  