/* Navbar.css */
* {
    padding: 0;
    box-sizing: border-box;
    margin: 0;
}

.navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2a629ad8;
    padding: 0.5rem;
    color: white;
    text-align: center;
    margin-top: 40px;
    position: absolute;
    transition: top 0.3s;
    z-index: 999;
}

.navbar.fixed {
    /* width: 100%; */
    position: fixed;
    top: -40px;
    left: 0;
    right: 0;
    box-shadow:
    inset 0 -3em 3em rgba(0, 0, 0, 0.1),
    0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}


.logo {
    margin-left: 150px;
    color: #fff;
    width: 260px;
    text-decoration: none;
}

.logo img {
    width: 50%;
}

.nav-links {
    display: flex;
    list-style: none;
}

.nav-links li {
    margin-right: 5rem;
}

.nav-links a {
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
    font-family: "DM Sans", sans-serif;
    font-weight: 500;
}

.nav-links a:hover {
    color: #da980a;
}

.mobile-menu-icon {
    display: none;
    font-size: 2rem;
    color: #fff;
    background: none;
    border: none;
    cursor: pointer;
    font-family: "DM Sans", sans-serif;
}


.dropdown {
    position: relative;
}

.dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0px;
    background-color: #2A629A;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 300px;
    text-align: left;
}

.dropdown-menu li {
    width: 300px;
}

.dropdown-menu a {
    display: block;
    padding: 0.5rem 1rem;
}

.dropdown-menu a:hover {
    background-color: #da980a;
    color: #fff;
}

.dropdown:hover .dropdown-menu {
    display: block;
}

@media (max-width: 1440px) {
    .logo {
        margin-left: 50px;
    }
}


@media (max-width: 1200px) {
    .navbar.fixed {
        width: 100%;
    }

    .logo {
        width: 140px;
        margin-left: 20px;
    }

    .nav-links a {
        font-size: 1rem;
    }

    .nav-links {
        margin-right: 1rem;
    }

    .mobile-menu-icon {
        display: block;
        margin-right: 1.5rem;
    }

    .nav-links {
        display: none;
        flex-direction: column;
        width: 100%;
    }

    .nav-links-mobile {
        display: flex;
        flex-direction: column;
        width: 100%;
        list-style-type: none;
    }

    .nav-links-mobile a {
        text-align: left;
        color: #fff;
        text-decoration: none;
        font-size: 1.2rem;
        font-family: "DM Sans", sans-serif;
        padding-top: 20px;
        font-weight: 500;
    }


    .nav-links li {
        margin: 1rem 0;
    }

}