.page {
    width: 100%;
    height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #2A629A;
}

header {
    text-align: center;
    margin-bottom: 20px;
}

.centered-div {
    width: 70%;
    height: 500px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
    border-radius: 10px;
} 

.home-h2 {
    font-family: "Playwrite NZ", cursive;
    font-size: 15px;
    color: #fff;
}

.home-h1 {
    margin-top: 0px;
    font-size: 30px;
    color: #fff;
    margin-bottom: 40px;
}


@media (max-width: 768px) {
    .page {
        height: auto;
        padding: 20px;
    }

    .centered-div {
        width: 100%;
        height: auto;
        margin-top: 20px;
    }

    .home-h1 {
        margin-bottom: 0;
    }
}