.uzb-container {
    width: 100%;
    padding: 50px 100px;
}

.uzb-flex {
    display: flex;
    margin-bottom: 20px;
}

.uzb-text {
    width: 50%;
    padding-right: 50px;
}

.uzb-text-left {
    width: 50%;
    padding-left: 50px;
}

.uzb-img {
    width: 50%;
    height: 350px;
}

.uzb-img-left {
    width: 50%;
    height: 350px;
}

.uzb-img-left img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.uzb-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.uzb-textt {
    color: #000;
    font-size: 18px;
    font-family: "Montserrat", serif;
    letter-spacing: 0px;
    text-align: justify;
}

@media (max-width: 1440px) {
    .uzb-text {
        padding-right: 20px;
    }

    .uzb-text-left {
        padding-left: 20px;
    }

    .uzb-textt {
        font-size: 14px;
    }

    .uzb-container {
        padding: 50px;
    }
}

@media (max-width: 960px) {
    .uzb-flex {
        display: block;
    }
    .uzb-text {
        width: 100%;
    }
    .uzb-img {
        width: 100%;
        margin-top: 20px;
    }

    .uzb-img-left {
        width: 100%;
    }
    .uzb-text-left {
        width: 100%;
        margin-top: 20px;
    }
}

@media (max-width: 768px) {
    .uzb-container {
        padding: 20px;
    }

    .uzb-text {
        padding-right: 0;
    }
    
    .uzb-text-left {
        padding-left: 0px;
    }
}