@import url('https://fonts.googleapis.com/css2?family=Playwrite+NZ:wght@100..400&display=swap');


.home-page {
    max-width: 100%;
    height: 600px;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    text-align: center;
}

.home-2 {
    font-family: "Playwrite NZ" , cursive; 
    font-size: 15px;
    color: #2A629A;
}

.home-1 {
    margin-top: 0px;
    font-size: 40px;
    color: #2A629A;
    margin-bottom: 60px;
}

.image-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
    margin-bottom: 50px;
    /* Center items horizontally */
}

.image-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 400px;
    /* Adjusted width */
    height: 280px;
    /* Adjusted height */
    text-align: center;
    /* Center items horizontally */
    border: 0.5rem solid #2A629A;
    overflow: hidden;
    padding: 2px;
}

.image-item img {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-in-out;
    object-fit: cover;
    /* Smooth zoom effect */
}

.image-item:hover img {
    transform: scale(1.1);
    /* Zoom effect */
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.316); /* 50% transparent black */
  }

.image-text {
    position: absolute;
    color: white;
    bottom: 10px;
    left: 10px;
    padding: 10px 25px;
    text-decoration: none;
    background-color: #0a0ac59d;
    font-family: 'Leo' serif;
    border-radius: 5px;
    font-weight: 600;
    font-size: 1.3rem;
}

.button-31 {
    background-color: #2A629A;
    border-radius: 20px;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: "Farfetch Basis","Helvetica Neue",Arial,sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    margin: 0;
    max-width: none;
    min-height: 44px;
    min-width: 10px;
    outline: none;
    overflow: hidden;
    padding: 9px 20px 8px;
    position: relative;
    text-align: center;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 200px;
  }
  
  .button-31:hover,
  .button-31:focus {
    opacity: .75;
  }

@media (max-width: 768px) {
    .image-grid{
        gap: 0;
    }
    .home-page {
        height: auto;
    }
    .image-item {
        width: 100%;
        margin-bottom: 50px;
    }
}


@media (min-width: 768px) {
    .home-page {
        height: auto;
    }
}
