/* App.css */
.tours-container {
    width: 100%;
    padding: 20px;
    background-color: #fff;
    padding-bottom: 100px;
    border-bottom: 2px solid #fff;
  }
  
  .tours-header {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 50px;
    color: #000;
    font-size: 3rem;
  }
  
  .card-tours {
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
    justify-content: center;
  }
  
  .tours-card {
    background-color: #2A629A;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    overflow: hidden;
    width: 300px; 
    height: 400px;
  }
  
  .tours-card img {
    border: 2px solid #fff;
    border-radius: 5px;
    width: 100%;
    height: auto;
  }
  
  .card-content {
    padding: 15px;
    color: #fff;
  }
  
  .card-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .card-description {
    font-size: 14px;
    color: #fff;
  }
  
  @media (max-width: 768px) {
    .tours-card {
      width: calc(50% - 20px); /* 2 cards per row on tablets */
    }
  }
  
  @media (max-width: 480px) {
    .tours-card {
      width: calc(100% - 20px); /* 1 card per row on mobile */
    }
  }
  