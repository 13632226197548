.container-contact {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 650px;
    padding: 20px;
    box-sizing: border-box;
    flex-wrap: wrap;
  }
  
  .contact-form,
  .contact-info {
    width: 500px;
    height: 600px;
    margin: 10px;
    background-color: #f9f9f9;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-family: "Montserrat", serif;
    box-sizing: border-box;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat", serif;
  }
  
  .contact-form input,
  .contact-form textarea,
  .contact-form button {
    width: 100%;
    padding: 20px;
    margin: 10px 0;
    border: 1px solid #ccc;
    font-size: 20px;
    border-radius: 4px;
    box-sizing: border-box;
    font-family: "Montserrat", serif;
  }
  
  .contact-form textarea {
    height: 100px;
    font-family: "Montserrat", serif;
    resize: none;
  }
  
  .contact-form button {
    background-color: #007bff;
    color: white;
    border: none;
    font-family: "Montserrat", serif;
    cursor: pointer;
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
    font-family: "Montserrat", serif;
  }
  
  .contact-info {
    display: flex;
    flex-direction: column;
    height: 500px;
    justify-content: center;
    font-family: "Montserrat", serif;
    background-color: #2A629A;
  }
  
  .info-item {
    display: flex;
    align-items: center;
    margin: 20px 0;
    font-family: "Montserrat", serif;
  }
  
  .info-item p {
    margin-left: 10px;
    color: #fff;
    font-family: "Montserrat", serif;
  }
  .info-item p a {
    margin-left: 10px;
    text-decoration: none;
    color: #fff;
    font-family: "Montserrat", serif;
  }
  
  .info-item svg {
    font-size: 24px;
    color: #fff;
    font-family: "Montserrat", serif;
  }
  
  @media (max-width: 768px) {
    .container-contact {
        height: auto;
        font-family: "Montserrat", serif;
    }
    .contact-info, .contact-form {
      width: 100%;
      height: auto;
      padding: 10px;
      margin: 10px 0;
      font-family: "Montserrat", serif;
    }
  }
  