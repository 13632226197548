* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.top-back {
    margin-top: 100px;
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../../images/top-back.jpg);
  }
  
  .content {
    text-align: center;
  }
  
  .content h2 {
    font-size: 5rem;
    color: #fff;
    padding: 10px 20px;
    background-color: rgba(0, 0, 0, 0.208);
  }

  .content p {
    font-size: 1rem;
    font-weight: 900;
    color: #fff;
    padding: 10px 50px;
    background-color: rgba(0, 0, 0, 0.208);
  }
  .about-container {
    display: flex;
    height: 550px;
  }
  
  .left-side {
    flex: 1;
    background-color: #2A629A;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 100%;
    padding: 80px;
  }
  
  .partner-img {
    width: 60%;
  }

  .right-side {
    flex: 1;
    background-color: #2A629A;
    color: #fff;
    padding: 20px;
    width: 60%;
    height: 100%;
    padding: 80px;
    padding-left: 0;
    text-align: left;
  }

  .right-side p {
    letter-spacing: 1px;
    font-size: 1rem;
  }
  
  .ser-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 700px;
    width: 100%;
    background-color: #fff;
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
    width: 40%;
  }
  
  .image-container img {
    width: 100%;
    height: auto;
    margin: 0 10px;
  }
  
  @media (max-width: 768px) {
    .image-container {
        gap: 0;
        width: 80%;
      flex-direction: column;
    }
  
    .image-container img {
      margin: 10px 0;
    }

    .partner-img {
      width: 100%;
    }
  }
  

  @media (max-width: 768px) {
    .content h2 {
        font-size: 1.4rem;
    }
    .about-container {
      flex-direction: column;
    }

    .content p {
      font-size: 0.7rem;
    }
    
    .right-side p {
        font-size: 1rem;
    }
  }

  @media (max-width: 1300px) {
    .image-container img {
        margin-top: 30px;
    }
    .ser-container {
        margin-top: 25rem;
        height: auto;
    }
    .about-container {
        flex-direction: column;
    }
    .left-side {
        width: 100%;
        padding: 20px;
    }
  
    .right-side {
      order: -1;
      max-height: 80%;
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
    }
  }
  

  
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .top-back {
      height: auto; /* Adjust height for smaller screens */
      padding: 20px; /* Optional padding adjustment */
    }
  }
  