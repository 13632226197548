/* App.css */
body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  
  
  /* Footer Styles */
  .footer {
    width: 100%;
    height: 400px;
    background-color: #2A629A;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .footer-row {
    display: flex;
    width: 100%;
    max-width: 1200px;
    justify-content: space-between;
    padding: 20px;
  }
  
  .footer-column {
    flex: 1;
    margin: 10px;
  }
  
  .footer-column h3 {
    margin-bottom: 20px;
  }
  
  .footer-column p, .footer-column ul, .footer-column input, .footer-column button {
    margin-bottom: 10px;
  }
  
  .footer-partner {
    width: 50%;
  }

  .footer-column ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-column input {
    width: calc(100% - 22px);
    padding: 10px;
    border: none;
    border-radius: 4px;
  }
  
  .footer-column button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #FFDA78;
    color: #333;
    cursor: pointer;
  }
  .footer-copyright {
    border-top: 2px solid white;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2A629A;
    color: #fff;
  }
  
  @media (max-width: 768px) {
    .footer {
        height: auto;
    }
  
    .footer-row {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-column {
      width: 100%;
      margin: 10px 0;
      text-align: center;
    }
  
    .footer-column input {
      width: calc(100% - 40px);
    }
  }
  
  @media (max-width: 480px) {
  
    .footer-column input {
      width: calc(100% - 40px);
    }
  }
  