@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.ticket-container {
    width: 100%;
    height: auto;
    padding: 50px 10px;
    background-color: #fff;
}

.ticket-cards {
    width: 100%;
    background-image: url(../../../images/train.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    /* height: 500px; */
    padding: 20px 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.ticket-cardss {
    width: 100%;
    background-image: url(../../../images/plane.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    /* height: 500px; */
    padding: 20px 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.ticket-title {
    text-align: center;
    font-family: "Montserrat", serif;
    padding-bottom: 30px;
    padding-top: 20px;
}

.ticket-card {
    width: 200px;
    height: 150px;
    background-color: #087ef385;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    cursor: pointer;
}

.ticket-card:hover {
    transform: scale(1.03);
    box-shadow: rgba(0, 0, 0, 0.45) 0px 10px 20px;
}


.from, .to {
    font-size: 18px;
    display: flex;
    align-items: center;
    font-weight: 900;
    color: #fff;
    background-color: #0000008c;
}

.available-date {
    font-size: 12px;
    font-family: "Montserrat", serif;
    margin: 5px 0px;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 10px;
}





@media (max-width: 1200px) {
    .ticket-cards {
        padding: 0px;
        gap: 10px;
    }

    .ticket-cardss {
        padding: 0px;
        gap: 10px;
    }

    .ticket-card {
        width: 150px;
    }
}