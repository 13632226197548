.mission-container {
    width: 100%;
    padding: 50px 200px;
}

.mission-title {
    text-align: center;
    font-size: 42px;
    color: #2A629A;
    padding-bottom: 30px;
}

.mission-text {
    color: #000;
    font-size: 18px;
    font-family: "Montserrat", serif;
    letter-spacing: 0px;
    text-align: justify;
}

.orange-text {
    color: #f1b963;
    font-weight: 700;
    font-size: 18px;
    font-family: "Montserrat", serif;
    letter-spacing: 0px;
    text-align: justify;
}

@media (max-width: 960px) {
    .mission-container {
        padding: 20px;
    }

    .mission-title {
        font-size: 22px;
    }
}