.container {
    width: 100%;
    height: 700px;
    background: url(../images/Header.png);
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    background-size: auto;
  }
  
  .content {
    text-align: center;
  }
  
  .responsive-text {
    font-size: 2vw; /* Responsive text size */
    color: white;
    margin-bottom: 20px;
  }

  
  .responsive-button {
    font-size: 2vw; /* Responsive button text size */
    padding: 10px 20px;
    color: blue;
    background-color: white;
    border: none;
    cursor: pointer;
  }

  .responsive-button {
    margin-top: 50px;
    background: #06447d;
    border: 1px solid #06447d;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    min-height: 40px;
    outline: 0;
    padding: 12px 20px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
  }
  
  
.responsive-button:hover,
.responsive-button:active {
  background-color: initial;
  background-position: 0 0;
  color: #fff;
}

.responsive-button:active {
  opacity: .5;
} 
  
  @media (max-width: 768px) {
    .responsive-text {
      font-size: 12vw;
    }
    

    .responsive-button {
      margin-top: 100px;
      padding: 20px;
      font-size: 5vw;
    }
  }
  